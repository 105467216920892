import React from 'react';
import { Link } from 'react-router-dom';

import './styles.css';


export default function Topo() {
    return (
        <div id="topo-header">
            <div className="container">
                <div className="contact">
                    <a href={`${process.env.REACT_APP_WHATSAPP_API}`}><i className="fa fa-whatsapp"></i> { process.env.REACT_APP_WHATSAPP }</a>                    
                    &nbsp; | &nbsp;
                    <a href={{pathname: process.env.REACT_APP_PHONE_LINK}}><i className="fa fa-phone"></i> { process.env.REACT_APP_PHONE }</a>
                    &nbsp; | &nbsp;
                    <a href={`mailto:${process.env.REACT_APP_EMAIL}`}><i className="fa fa-envelope-o"></i> { process.env.REACT_APP_EMAIL }</a>
                </div>

                <div className="social">
                    <Link to={{pathname: process.env.REACT_APP_INSTAGRAM}} target='_blank'><i className="fa fa-instagram" aria-hidden="true"></i></Link>
                    <Link to={{pathname: process.env.REACT_APP_FACEBOOK}} target='_blank'><i className="fa fa-facebook" aria-hidden="true"></i></Link>
                    <Link to={{pathname: process.env.REACT_APP_BLOG}} target='_blank'><i className="fa fa-newspaper-o" aria-hidden="true"></i></Link>                        
                </div>
            </div>
        </div>        
    );
  
}